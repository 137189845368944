import { useState, useEffect, useContext, createContext } from 'react'
import Cookies from 'js-cookie'

import {
  BAMBUS_COUNTRY_CODE,
  BAMBUS_COUNTRY_CODE_QUERY_PARAM_KEY,
  BAMBUS_GTM_COUNTRY_DATA_LAYER_VAR_KEY,
  getParam as getParamFromURL,
} from 'utils'
import { fetchLocation } from 'api-utils/location'

const LocationContext = createContext()

export function LocationProvider({ children }) {
  /**
   * Location set logic:
   * 1. If countrycode query param in the URL, use that
   * 2. If countrycode in the cookies (website was used before), use that
   * 3. Is tracking allowed?
   *     i. Yes - Call a location service which tries to identify the country. If that fails set the default value (AT)
   *    ii.  No - Set the default value (Austria = AT)
   */

  const countryCodeFromCookie = Cookies.get(BAMBUS_COUNTRY_CODE)

  const [location, setLocation] = useState({
    countryCode:
      getParamFromURL(BAMBUS_COUNTRY_CODE_QUERY_PARAM_KEY)?.toUpperCase() ||
      countryCodeFromCookie ||
      'AT',
  })

  const setCountryCode = (code) => {
    setLocation({ ...location, countryCode: code })
    window.dataLayer?.push({
      [BAMBUS_GTM_COUNTRY_DATA_LAYER_VAR_KEY]: code,
    })
    window.dataLayer?.push({
      event: 'country_changed',
    })
    window._paq?.push(['trackEvent', 'country_changed', code])
    Cookies.set(BAMBUS_COUNTRY_CODE, code, {
      expires: 1,
    })
  }

  useEffect(() => {
    /**
     * The country code from the URL takes precedence (if present)
     */
    const countryCodeFromURL = getParamFromURL(
      BAMBUS_COUNTRY_CODE_QUERY_PARAM_KEY
    )
    if (countryCodeFromURL) {
      const countryCodeFromURLUppercase = countryCodeFromURL.toUpperCase()
      setCountryCode(countryCodeFromURLUppercase)
      return
    }

    if (countryCodeFromCookie !== undefined) {
      setLocation({ ...location, countryCode: countryCodeFromCookie })
      window.dataLayer?.push({
        [BAMBUS_GTM_COUNTRY_DATA_LAYER_VAR_KEY]: countryCodeFromCookie,
      })
      return
    }

    ;(async function () {
      try {
        const { country_code: countryCode } = await fetchLocation()
        setCountryCode(countryCode)
      } catch (e) {}
    })()
  }, [])

  return (
    <LocationContext.Provider value={{ ...location, setCountryCode }}>
      {children}
    </LocationContext.Provider>
  )
}

export const useLocation = () => {
  return useContext(LocationContext)
}

export const useTranslate = () => {
  const { countryCode } = useLocation()

  const t = (translation = {}) => {
    if (countryCode in translation) {
      return translation[countryCode]
    }

    return translation['_'] || null
  }

  return t
}
