import Cookies from 'js-cookie'

export const getIsNecessaryTrackingAllowed = () => {
  const trackingCookie = Cookies.get('CookieConsent')

  if (!trackingCookie) {
    return false
  }

  //   this should be of form: necessary:true
  const necessaryCookiePart = decodeURIComponent(trackingCookie).split(',')[1]
  if (!necessaryCookiePart) {
    return false
  }
  const parts = necessaryCookiePart.split(':')
  if (parts.length === 0) {
    // this is unexpected
    return false
  }

  return parts[1] === 'true'
}

export const getIsPreferencesTrackingAllowed = () => {
  const trackingCookie = Cookies.get('CookieConsent')

  if (!trackingCookie) {
    return false
  }

  //   this should be of form: necessary:true
  const necessaryCookiePart = decodeURIComponent(trackingCookie).split(',')[2]
  if (!necessaryCookiePart) {
    return false
  }
  const parts = necessaryCookiePart.split(':')
  if (parts.length === 0) {
    // this is unexpected
    return false
  }

  return parts[1] === 'true'
}

export const getIsStatisticsTrackingAllowed = () => {
  const trackingCookie = Cookies.get('CookieConsent')

  if (!trackingCookie) {
    return false
  }

  //   this should be of form: necessary:true
  const necessaryCookiePart = decodeURIComponent(trackingCookie).split(',')[3]
  if (!necessaryCookiePart) {
    return false
  }
  const parts = necessaryCookiePart.split(':')
  if (parts.length === 0) {
    // this is unexpected
    return false
  }

  return parts[1] === 'true'
}

export const getIsMarketingTrackingAllowed = () => {
  const trackingCookie = Cookies.get('CookieConsent')

  if (!trackingCookie) {
    return false
  }

  //   this should be of form: necessary:true
  const necessaryCookiePart = decodeURIComponent(trackingCookie).split(',')[4]
  if (!necessaryCookiePart) {
    return false
  }
  const parts = necessaryCookiePart.split(':')
  if (parts.length === 0) {
    // this is unexpected
    return false
  }

  return parts[1] === 'true'
}
