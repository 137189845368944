import 'whatwg-fetch'
import React from 'react'
import loadScript from 'simple-load-script'
import TagManager from 'react-gtm-module'
import Cookies from 'js-cookie'

import {
  isBrowser,
  cookieSetup,
  saveEntryURL,
  MATOMO_TRACKING_COOKIE_KEY,
  BAMBUS_GTM_AB_TESTING_DATA_LAYER_VAR_KEY,
} from 'utils'
import { GTM_ID, GTM_AUTH, GTM_PREVIEW, GIT_BRANCH } from 'config'
import { getIsMarketingTrackingAllowed } from 'tracking'
import Provider from 'components/Provider'

import './src/animations.css'

const initializeGO = () => {
  loadScript(`https://www.googleoptimize.com/optimize.js?id=OPT-N4RK8TC`)
}

// As we are using domain wide cookie consent and the Matomo cookies
// are not shared, we have to check whether we should notify
// Matomo that we do have a consent available from before (not by clicking on the "Accept Cookies" on
// Landing) - this will in turn create the necessary Matomo cookies if there isn't any yet
const initializeMatomo = () => {
  const alreadyExistingMatomoTrackingCookie = Cookies.get(
    MATOMO_TRACKING_COOKIE_KEY
  )
  if (getIsMarketingTrackingAllowed() && !alreadyExistingMatomoTrackingCookie) {
    window._paq && window._paq.push(['rememberCookieConsentGiven'])
  }
}

// Google Tag Manager
const initializeGTM = () => {
  TagManager.initialize({
    gtmId: GTM_ID,
    auth: GTM_AUTH,
    preview: GTM_PREVIEW,
    events: {
      sendUserInfo: 'userInfo',
    },
  })

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    [BAMBUS_GTM_AB_TESTING_DATA_LAYER_VAR_KEY]: GIT_BRANCH,
  })
}

const updateMatomo = (location) => {
  if (window._paq) {
    window._paq.push(['setCustomUrl', location])
    window._paq.push(['setDocumentTitle', document.title])
    window._paq.push(['trackPageView'])
  }
}

const updateGTM = (payload) =>
  window.dataLayer && window.dataLayer.push(payload)

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== 'production') {
    return null
  }

  setTimeout(() => {
    updateMatomo(location.href)

    updateGTM({ event: 'pageView', pagePath: location.href })
  }, 50)
}

// -----------------------------------

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!('IntersectionObserver' in window)) {
    import('intersection-observer')
  }

  initializeGO()
  initializeMatomo()

  if (isBrowser) {
    cookieSetup()
    saveEntryURL()
  }

  if (process.env.NODE_ENV !== 'production') {
    return null
  }

  initializeGTM()
}

export const wrapRootElement = ({ element }) => {
  return <Provider>{element}</Provider>
}
